import {FuseNavigationItem} from "../../../@fuse/components/navigation";

export class FilterCategory implements FuseNavigationItem {
    id: string;
    title: string;
    tooltip: string;
    badge: any;
    active: boolean;
    meta: any;
    type: "aside" | "basic" | "collapsable" | "divider" | "group" | "spacer";

    constructor(id, title, type, tooltip, badge, active, meta?) {
        this.type = type;
        this.id = id;
        this.title = title;
        this.tooltip = tooltip;
        this.badge = badge;
        this.active = active;
        this.meta = meta;
    }

    function(): void {}


}
