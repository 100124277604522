import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

    public error$ = this.auth.error$;

    constructor(private auth: AuthService, private router: Router) {}

    ngOnInit(): void {
        this.error$.subscribe((error) => {
            console.error(error);
        });
    }

    logout(): void {
        this.auth.logout({returnTo: window.location.origin});
    }
}
