import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import {AuthService} from '@auth0/auth0-angular';
import {ApiService} from '../services/api.service';
import {
    Observable,
    map,
    switchMap,
    catchError,
    concat,
    interval,
    startWith,
    takeWhile,
    ignoreElements, of
} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
/**
 * This guards protects the app from users which don't have access to the Eglo Space.
 */
export class AccessGuard implements CanActivate {

    constructor(
        private _auth0: AuthService,
        private _apiService: ApiService,
        private _router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | Observable<boolean> {

        let isLoading = true;

        this._auth0.isLoading$.subscribe((res) => {
            isLoading = res;
        });

        const source2 = this._auth0.getAccessTokenSilently().pipe(map(accessToken => ({
            accessToken: accessToken, valid: true
        })), catchError(err => of({accessToken: null, valid: false})));

        return concat(
            interval(100).pipe(
                startWith(0),
                takeWhile(() => isLoading),
                ignoreElements()
            ),
            source2
        ).pipe(switchMap((data) => {
            if (data.valid) {
                return this._apiService.getAllSpaces(data.accessToken)
            } else {
                return of(false);
            }
        }))
            .pipe(switchMap((response) => {
                if (response) {
                    if (response.some(space => space.token === environment.spaceToken)) {
                        return this._auth0.user$;
                    } else {
                        return of(false);
                    }
                } else {
                    return of(undefined);
                }
            })).pipe(map((user: any) => {
                // Check for user and if user is not an Eglo employee.
                // This is only a workaround for users that are blocked, but already logged in.
                if (user === undefined) {
                    this._auth0.loginWithRedirect({
                        redirect_uri: window.location.origin + '/callback',
                        appState: {
                            target: window.location.pathname + window.location.search
                        }
                    });
                } else if (user === false) {
                    this._router.navigate(['authorization']);
                } else if (!user.email.includes('@eglo.com')) {
                    return true;
                }
                this._router.navigate(['error']);
            }));
    }
}

