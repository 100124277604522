import {Component} from "@angular/core";
import {AuthService} from "@auth0/auth0-angular";
import {map} from "rxjs";
import {Router} from "@angular/router";

@Component({
    selector: 'callback',
    template: ''
})
export class CallbackComponent {

    constructor(private authService: AuthService) {
        this.authService.handleRedirectCallback()
            .pipe(map(result => result?.appState))
            .subscribe(appState => {
            });
    }
}
