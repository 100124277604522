<div class="flex justify-end">
    <button mat-icon-button>
        <mat-icon svgIcon="mat_outline:close" class="cursor-pointer" mat-dialog-close="closed"></mat-icon>
    </button>

</div>

<div class="flex flex-col items-center gap-2 mb-4">
    <img
            src="../../../assets/images/upload-to-cloud-svgrepo-com.svg"
            alt="upload-pic">

    <h3> Upload file</h3>
    <p>The file must be in csv format</p>
</div>


<div ng2FileDrop
     class="drop-zone"
     [uploader]="uploader"
     (onFileDrop)="handleFileSelect($event)">

    <input type="file" accept=".csv" id="fileDropRef" (change)="handleFileSelect($event)" [uploader]="uploader"
           ng2FileSelect>

    <p class="text-black/60">Drag and drop your file here or</p>
    <label for="fileDropRef" class="text-blue-500/80 font-bold">browse</label>
</div>
<div class="m-4">
    <div *ngFor="let item of uploader.queue" class="flex items-center gap-4 my-2">

        <p>Filename: <strong>{{ item?.file?.name }}</strong></p>
        <p *ngIf="uploader.options.isHTML5">Size: {{ item?.file?.size / 1024 / 1024 | number:'.2' }} MB</p>


        <div class="text-center">
            <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
            <span *ngIf="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
            <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
        </div>
    </div>
</div>

<fuse-alert [type]="'success'" *ngIf="fileUploaded">
    <span fuseAlertTitle>File uploaded!</span>
    The metadata import job has started.
</fuse-alert>

<fuse-alert [type]="'warning'" *ngIf="fileType && fileType !== 'text/csv'">
    <span fuseAlertTitle>File not supported!</span>
    Please use a .csv file.
</fuse-alert>
