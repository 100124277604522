import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, concatMap, EMPTY, expand, forkJoin, map, merge, mergeMap, Observable, of, range, shareReplay, switchMap, tap, toArray} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Group, Issue, IssueResponse, IssueType} from '../interfaces/interfaces';
import {MainService} from '../../modules/main/services/main.service';
import {FuseConfirmationService} from '../../../@fuse/services/confirmation';
import Bugsnag from '@bugsnag/js';
import {SharedService} from './shared.service';
import {MetadataObject} from "../../modules/metadata-upload/metadata-upload.types";
import {CockpitItemModel} from "../models/cockpit-item.model";
import {SmartVu} from "../interfaces/smartvu.interface";

@Injectable({providedIn: 'root'})
/**
 * Service providing API calls which are used across all components.
 */
export class ApiService {

    private _allIssues$ = this.http.get(`${environment.apiBaseUrl}/v4/issues?space_token=${environment.spaceToken}`)
        .pipe(
            shareReplay(),
            map((data: any) => data),
            catchError((error) => {
                throw error;
            }));


    constructor(private http: HttpClient,
                private mainService: MainService,
                private fuseConfirmationService: FuseConfirmationService,
                private sharedService: SharedService) {
    }

    /**
     * Returns all SmartVu's from the Eglo Space.
     */
    getSmartVusByPage(page: number, ftpItems: any): Observable<{ smartvus: CockpitItemModel[], allPages: number }> {
        let pageParam = page;
        if (!pageParam) {
            pageParam = 1;
        }
        return this.http.get(`${environment.apiBaseUrl}/v4/spaces/${environment.spaceToken}/smartvus?web=1`, {
            params: new HttpParams().set('page', pageParam),
            observe: 'response'
        })
            .pipe(
                switchMap((response: any) => {
                    const data = response.body;
                    const allPages = Math.ceil(response.headers.get('x-vf-total') / response.headers.get('x-vf-per-page'));

                    const mappedArray = data.map((el) => {

                        const duplicates = data.filter(i => i.title === el.title && i.token !== el.token);
                        const featureObject = el.features.find((feature: any) => feature.token === el.initial_feature);

                        if (duplicates.length > 0 && featureObject) {

                            if (featureObject.custom_json.source_file_updated_at) {
                                el['duplicates'] = duplicates;
                                el['duplicate'] = false;
                            } else {
                                el['duplicate'] = true;
                            }
                        }
                        return el;
                    });

                    const filtered = mappedArray.filter(el => el.features.find((item: any) => item.feature_type !== 'smartview_collection') && !el.duplicate);

                    return forkJoin(
                        {
                            cockpitItems: of(filtered),
                            ftpItems: of(ftpItems),
                            allPages: of(allPages)
                        }
                    )
                }),
                map(({cockpitItems, ftpItems, allPages}) => {


                    for (const ftpItem of ftpItems) {
                        const materialNumber = ftpItem?.name?.split('.zip')[0];

                        if (materialNumber) {
                            const cockpitItem = cockpitItems.find(item => item.title.includes(materialNumber));

                            if (cockpitItem) {
                                cockpitItem['ftp_updated_at'] = ftpItem.modifiedAt;
                            }
                        }
                    }

                    return {smartvus: cockpitItems, allPages: allPages};
                }),
                catchError((error) => {
                    this.showErrorDialog();
                    Bugsnag.notify(error);
                    console.log(error)
                    throw error;
                }));
    }

    /**
     * Returns all SmartVu's from the Eglo Space.
     */
    allSmartVus(): Observable<{ smartvus: CockpitItemModel[], allPages: number } | { smartvus: CockpitItemModel[] }> {

        return this.getFtpItems().pipe(
            switchMap((ftpItems: any) => {
                return forkJoin({ftpItems: of(ftpItems), getSmartVusByPageResponse: this.getSmartVusByPage(1, ftpItems)})
            }),
            switchMap(({ftpItems, getSmartVusByPageResponse}) => {

                const {smartvus, allPages} = getSmartVusByPageResponse;

                if (smartvus && smartvus.length !== 0) {
                    if (allPages) {
                        return range(1, allPages)
                            .pipe(
                                mergeMap((i: number) => {
                                    if (i === 1) {
                                        return of({smartvus: smartvus});
                                    }
                                    return this.getSmartVusByPage(i, ftpItems)
                                }, 10)
                            )
                    }

                    return of({smartvus: smartvus});
                }
            }),
        )
    }

    get allIssues$(): Observable<IssueType[]> {
        return this._allIssues$;
    }

    /**
     * Loads a Share object from the Vuframe API by its share code
     *
     * @param shareCode the share code of the requested Share
     * @returns an Observable that returns the Share on success
     */
    getShare(shareCode: string): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/v2/share/${shareCode}`, this.getHeaders(environment.apiUsername, environment.apiToken));
    }


    getAllSpaces(accessToken: string): Observable<any> {
        return this.getAuthToken(accessToken)
            .pipe(
                switchMap((response) => this.http.get(`${environment.apiBaseUrl}/public/v1/projects`, this.getHeaders(environment.ssoAppToken, response.token)))
            );
    }

    getAuthToken(accessToken): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'authorization': `Bearer ${accessToken}`,
            }),
        };

        return this.http.post(`${environment.apiBaseUrl}/public/v1/sso/${environment.ssoAppToken}/create_token`, null, httpOptions);
    }

    /**
     * Loads a SmartVu object from the Vuframe API by its identifier
     *
     * @param identifier the unique identifier of the SmartVu
     * @returns an Observable that returns the SmartVu on success
     */
    getSmartVu(identifier: string): Observable<CockpitItemModel> {
        return this.http.get(`${environment.apiBaseUrl}/v2/viewer/experience/${identifier}?web=1`, this.getHeaders(environment.apiUsername, environment.apiToken))
            .pipe(
                map((smartvu: SmartVu) => new CockpitItemModel(smartvu))
            );
    }

    /**
     * Gets all issues of a smartvu by its identifier
     *
     * @param token the token of a smartvu
     * @returns an Observable that returns the SmartVu on success
     */
    getIssues(token: string): Observable<Array<IssueResponse>> {
        return this.http.get(`${environment.apiBaseUrl}/v4/issues/owners/${token}`, this.getHeaders(environment.ssoAppToken, environment.accessSecret))
            .pipe(
                map((data: any) => {
                    const issues = [];
                    for (const item of data) {
                        const issue = {
                            code: item.issue.code,
                            comment: item.comment,
                            ownerToken: token,
                            fixed: item.fixed,
                            issueType: {
                                code: item.issue.code,
                                title: item.issue.title,
                                level: item.issue.level,
                                description: item.issue.description
                            }
                        };
                        issues.push(issue);
                    }
                    return issues;
                })
            );
    }

    /**
     * Fix issue of a smartvu
     *
     * @param issue that will be fixed
     * @returns an Observable that returns the SmartVu on success
     */
    fixIssue(issue: Issue): Observable<IssueResponse> {
        return this.http.post(`${environment.apiBaseUrl}/v4/issues/${issue.code}/owners/${issue.ownerToken}/fix`, null, this.getHeaders(environment.ssoAppToken, environment.accessSecret))
            .pipe(
                map((data: any) => ({
                    code: data.issue.code,
                    comment: data.comment,
                    ownerToken: data.owner_token,
                    fixed: data.fixed,
                    issueType: {
                        code: data.issue.code,
                        title: data.issue.title,
                        level: data.issue.level,
                        description: data.issue.description
                    }
                }))
            );
    }

    deleteIssue(issueCode: string, ownerToken: string): Observable<any> {
        return this.http.delete(
            `${environment.apiBaseUrl}/v4/issues/${issueCode}/owners/${ownerToken}`, this.getHeaders(environment.ssoAppToken, environment.accessSecret));
    }

    openIssue(issueCode: string, ownerToken: string): Observable<any> {
        return this.http.post(
            `${environment.apiBaseUrl}/v4/issues/${issueCode}/owners/${ownerToken}/open`, this.getHeaders(environment.ssoAppToken, environment.accessSecret))
            .pipe(
                map((data: any) => ({
                    code: data.issue.code,
                    comment: data.comment,
                    ownerToken: data.owner_token,
                    fixed: data.fixed,
                    issueType: {
                        code: data.issue.code,
                        title: data.issue.title,
                        level: data.issue.level,
                        description: data.issue.description
                    }
                }))
            )
    }

    /**
     * Get all groups within a Studio space
     *
     * @param token of Studio space
     * @returns an Observable that returns the SmartVu on success
     */
    getGroupsOfSpace(token: string): Observable<Array<Group>> {
        return this.http.get(`${environment.apiBaseUrl}/v4/spaces/${token}/groups`, this.getHeaders(environment.ssoAppToken, environment.accessSecret))
            .pipe(
                map((data: any) => {
                    const groups = [];
                    for (const group of data) {
                        groups.push({
                            title: group.title,
                            token: group.token,
                            status: this.sharedService.getStatusByTitle(group.title)
                        });
                    }
                    return groups;
                })
            );
    }

    /**
     * Publish App Content to LIVE
     *
     * @returns an Observable that returns the App on success
     */
    publishApp(): Observable<any> {
        return this.http.post(`${environment.apiBaseUrl}/public/v1/apps/${environment.appToken}/publish?force=1`, null, this.getHeaders(environment.ssoAppToken, environment.accessSecret));
    }

    /**
     * Returns current running metadata jobs
     */
    getMetadataJobsStatus(): Observable<any> {
        return this.http.get(`${environment.egloMiddlewareAPI}/metadata`, this.getHeaders(environment.ssoAppToken, environment.accessSecret));
    }

    /**
     * Kick off a new job by adding it to the metadata queue
     *
     * @param mappedMetadata - Array of all metadata objects.
     */
    startMetadataJob(mappedMetadata: MetadataObject[]): Observable<any> {
        const observables = mappedMetadata.map(el => this.http.post(`${environment.egloMiddlewareAPI}/metadata`, el, this.getHeaders(environment.ssoAppToken, environment.accessSecret)));
        return merge(...observables).pipe(toArray());
    }

    /**
     * Start the import of the 3D Models
     *
     */
    start3DModelsImport(): Observable<any> {
        return this.http.post(`${environment.egloMiddlewareAPI}/upload`, null, this.getHeaders(environment.ssoAppToken, environment.accessSecret));
    }

    /**
     * Returns current running metadata jobs
     */
    getModelsImportStatus(): Observable<any> {
        return this.http.get(`${environment.egloMiddlewareAPI}/upload`, this.getHeaders(environment.ssoAppToken, environment.accessSecret));
    }

    getFtpItems(): Observable<any> {
        return this.http.get(`${environment.egloMiddlewareAPI}/ftp-items`, this.getHeaders(environment.ssoAppToken, environment.accessSecret))
            .pipe(
                map((data: any) => data.ftpItems)
            );
    }

    /**
     * This method reprocesses the SmartVu Model.
     */
    reprocessSmartVu(modelToken: string): Observable<any> {
        return this.http.post(`${environment.apiBaseUrl}/v3/aura/models/${modelToken}/reprocess`, {}, this.getHeaders(environment.ssoAppToken, environment.auraAPISecret));
    }

    /**
     * This method publishes the SmartVu to draft content of the app.
     *
     * @param token - token of smartvu
     * @param typeCode - type code of lamp category
     * @param group - group to set
     */
    publishSmartVu(token, customJson): Observable<any> {
        let typeCode = null;
        if (customJson.metadata) {
            typeCode = customJson.metadata.type_code
        }

        return this.http.post(`${environment.egloMiddlewareAPI}/content/publish`, {
            smartvu_token: token,
            app_token: environment.appToken,
            type_code: typeCode,
            custom_json: customJson
        }, this.getHeaders(environment.ssoAppToken, environment.accessSecret));
    }

    /**
     * This method unpublishes the SmartVu from the app.
     *
     * @param token - token of smartvu
     * @param typeCode - type code of lamp category
     */
    unpublishSmartVu(token, customJson): Observable<any> {
        let typeCode = null;
        if (customJson.metadata) {
            typeCode = customJson.metadata.type_code
        }

        return this.http.post(`${environment.egloMiddlewareAPI}/content/unpublish`, {
            smartvu_token: token,
            app_token: environment.appToken,
            type_code: typeCode,
            custom_json: customJson
        }, this.getHeaders(environment.ssoAppToken, environment.accessSecret));
    }

    /**
     * This method updates the groups property of a SmartVu.
     *
     * @param smartvuToken - The token of the SmartVu which will be updated.
     * @param groups - new groups
     */
    replaceSmartVuGroups(smartvuToken: string, groups: Array<Group>): Observable<Group[]> {
        return this.http.put(
            `${environment.apiBaseUrl}/v4/smartvus/${smartvuToken}/groups`, this.getGroupTokens(groups),
            {
                headers: {
                    'contentType': 'application/json',
                }
            }).pipe(
            map((response: any) => {
                const result = [];
                for (const item of response) {
                    result.push({
                        status: this.sharedService.getStatusByTitle(item.title),
                        token: item.token,
                        title: item.title
                    });
                }
                return result;
            })
        );

    }

    /**
     * This method retrieves all groups of a SmartVu.
     *
     * @param smartvuToken - The token of the SmartVu.
     */
    getSmartVuGroups(smartvuToken: string): Observable<Group[]> {
        return this.http.get(
            `${environment.apiBaseUrl}/v4/smartvus/${smartvuToken}/groups`,
            {
                headers: {
                    'contentType': 'application/json',
                }
            }).pipe(
            map((response: any) => {
                const result = [];
                for (const item of response) {
                    result.push({
                        status: this.sharedService.getStatusByTitle(item.title),
                        token: item.token,
                        title: item.title
                    });
                }
                return result;
            })
        );
    }

    /**
     * Adds an issue to a SmartVu
     *
     * @param issue - Issue with owner, issue code and comment.
     */
    addIssueToSmartVu(issue: Issue): Observable<any> {
        const body = {
            comment: issue.comment
        };

        return this.http.post(
            `${environment.apiBaseUrl}/v4/issues/${issue.code}/owners/${issue.ownerToken}`, body,
            this.getHeaders(environment.ssoAppToken, environment.accessSecret));
    }

    /**
     * Edit issue comment
     *
     * @param issue - Issue with owner, issue code and comment.
     * @param comment - Edited comment.
     */
    editIssueComment(issue: Issue, comment: string): Observable<any> {
        const body = {
            comment: comment
        };

        return this.http.patch(
            `${environment.apiBaseUrl}/v4/issues/${issue.code}/owners/${issue.ownerToken}`, body,
            this.getHeaders(environment.ssoAppToken, environment.accessSecret));
    }

    /**
     * Updates the 'custom_json' property with date when the group was last changed.
     *
     * @param smartVuToken - Token of the SmartVu.
     * @param customJson - the custom_json property of the SmartVu
     */
    updateGroupChangeTimestamp(smartVuToken: string, customJson: any): Observable<any> {

        let obj: {};

        if (customJson['group_updated_at']) {

            customJson['group_updated_at'] = new Date().toISOString();

            obj = {
                custom_json: {
                    ...customJson
                }
            };
        } else {
            obj = {
                custom_json: {
                    group_updated_at: new Date().toISOString(),
                    ...customJson
                }
            };
        }

        return this.http.patch(
            `${environment.apiBaseUrl}/v4/smartvus/${smartVuToken}`, obj, this.getHeaders(environment.ssoAppToken, environment.accessSecret));

    }

    /**
     * Returns all pages of an app.
     * Each object of the response contains 'is_feature_live' property.
     * Using it, we can divide the products to live and draft.
     */
    getAppPages(): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/v4/apps/${environment.appToken}/features?live=1&platform=mobile`, this.getHeaders(environment.ssoAppToken, environment.accessSecret))
    }

    getProductByMaterialNumber(number) {
        return this.http.get(`${environment.TD_BASE_URL}/getVuFrameProduct/${number}`, {
            headers: {
                'Authorization': this.getEgloBasicAuth(),
                'X-Authorization': this.getBearerToken()
            }
        });
    }


    // Private Helpers

    private getEgloBasicAuth() {
        const token = environment.TD_USERNAME;
        const secret = environment.TD_PASSWORD;
        const auth = `${token}:${secret}`;
        const basic_auth = Buffer.from(auth).toString('base64');

        return "Basic " + basic_auth
    }

    private getBearerToken() {
        const token = environment.TD_TOKEN;
        return `Bearer ${token}`;
    }

    private showErrorDialog(): void {
        const dialog = this.fuseConfirmationService.open({

            'title': 'Could not retrieve all products!',
            'message': '<br>The products could not be fetched.</br><br>This is caused by a poor internet connection.</br><br>Please try again with a better internet connection or in a few seconds.</br>',
            'icon': {
                'show': true,
                'name': 'heroicons_outline:refresh',
                'color': 'warn'
            },
            'actions': {
                'confirm': {
                    'show': true,
                    'label': 'Reload Page',
                    'color': 'primary'
                },
                'cancel': {
                    'show': false
                }
            },
            'dismissible': true

        });

        dialog.afterClosed().subscribe((res) => {
            if (res === 'confirmed') {
                window.location.reload();
            }
        });
    }

    private getHeaders(token: string, secret: string): { headers: HttpHeaders } {
        return {
            headers: new HttpHeaders({
                'contentType': 'application/json',
                'authorization': `Basic ${btoa(`${token}:${secret}`)}`,
            })
        }
    }

    private getGroupTokens(groups: Group[]): any {
        const groupTokens = groups.map(function (group) {
            return group.token;
        });

        return {groups: groupTokens};
    }
}
