import {Injectable} from '@angular/core';
import {UploadFileComponent} from '../../metadata-upload/upload-file.component';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {Status} from '../../../shared/enums/enums';
import {CockpitItem, Group} from '../../../shared/interfaces/interfaces';
import {MetadataUploadService} from '../../metadata-upload/services/metadata-upload.service';
import {IssuesService} from '../../../shared/components/issues/issues.service';
import {SharedService} from '../../../shared/services/shared.service';
import {CockpitItemModel} from "../../../shared/models/cockpit-item.model";

@Injectable({providedIn: 'root'})
export class MainService {

    private _searchValue$ = new BehaviorSubject<string>(null);
    searchValue$ = this._searchValue$.asObservable();

    private _isAdmin$ = new BehaviorSubject<boolean>(null);
    isAdmin$ = this._isAdmin$.asObservable();

    private _appMode$ = new BehaviorSubject<string>(null);
    appMode$ = this._appMode$.asObservable();

    private _groups$ = new BehaviorSubject<Array<Group>>(null);

    private _hideSearchBar = new BehaviorSubject<boolean>(false);
    hideSearchBar$ = this._hideSearchBar.asObservable();

    constructor(private _dialog: MatDialog,
                private _metadataUploadService: MetadataUploadService,
                private _issuesService: IssuesService,
                private _sharedService: SharedService
    ) {
    }

    generateCockpitItems(response: any, issues: any): CockpitItemModel[] {
        const cockpitElementsList: CockpitItemModel[] = [];

        for (let i = 0; i < response.length; i++) {
            const cockpitElement = new CockpitItemModel(response[i], i + 1, issues);
            cockpitElementsList.push(cockpitElement);
        }

        const newIssues = this._issuesService.issuePillsArray;
        const deletedIssues = this._issuesService.deleteIssuePillsArray;

        if (newIssues.length > 0) {
            for (const item of newIssues) {
                const elementIndex = cockpitElementsList.findIndex(el => el.smartVuToken === item.ownerToken);

                const obj = {
                    issue: {
                        code: item.code
                    }
                };
                cockpitElementsList[elementIndex].issues.push(obj);
            }
        }

        if (deletedIssues.length > 0) {
            for (const item of deletedIssues) {
                const elementIndex = cockpitElementsList.findIndex(el => el.smartVuToken === item.owner_token);
                const index = cockpitElementsList[elementIndex].issues.findIndex(el => el.issue.code === item.issue.code);

                if (index > -1) {
                    cockpitElementsList[elementIndex].issues.splice(index, 1);
                }
            }
        }

        return cockpitElementsList;
    }

    /**
     * Opens the Upload File popup dialog
     */
    openFileUploadDialog(allSmartVus): void {
        this._dialog.open(UploadFileComponent, {
            data: {
                allSmartVus: allSmartVus
            },
            autoFocus: false
        });
    }

    // Helper functions

    getGroupTitle(groups: any[]): string {

        const groupStatus = groups.map(group => group.status);

        switch (true) {
            case groupStatus.includes(Status.bugfixing):
                return 'Bugfixing';
            case groupStatus.includes(Status.invalidSource):
                return 'Invalid Source';
            case groupStatus.includes(Status.rejected):
                return 'Rejected';
            case groupStatus.includes(Status.published):
                return 'Published';
            case groupStatus.includes(Status.approved):
                return 'Approved';
            case groupStatus.includes(Status.toReview):
                return 'To Review';
            case groupStatus.includes(Status.deleted):
                return 'Deleted';
            default:
                return 'New';
        }
    }

    isInStatus(status: Status[], groups: Group[]): boolean {
        return groups.some(el => status.includes(el.status));
    }

    // Setters

    searchValue(value: string): void {
        this._searchValue$.next(value);
    }

    isAdmin(value: boolean): void {
        this._isAdmin$.next(value);
    }

    appMode(value: boolean): void {
        if (value) {
            this._appMode$.next('admin');
        } else {
            this._appMode$.next('user');
        }
    }

    groups(groups: Array<Group>): void {
        this._groups$.next(groups);
    }

    hideSearchBar(value: boolean): void {
        this._hideSearchBar.next(value);
    }

    // getters

    getGroups(): Array<Group> {
        return this._groups$.getValue();
    }

}
