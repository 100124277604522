import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
/**
 * This service is used to store observables with which the components can handle the loading events.
 */
export class LoadingService {
    private _isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isLoading$: Observable<boolean> = this._isLoading.asObservable();

    constructor() {
    }

    setIsLoading(value: boolean): void {
        this._isLoading.next(value);
    }

}
