import {Injectable} from '@angular/core';
import {finalize, Observable, Subject, take} from 'rxjs';
import {FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent} from '../../../../@fuse/components/navigation';
import {FilterCategories, Status} from '../../../shared/enums/enums';
import {ApiService} from '../../../shared/services/api.service';
import {ActivatedRoute, Router} from "@angular/router";
import {CockpitItem} from "../../../shared/interfaces/interfaces";
import {SharedService} from "../../../shared/services/shared.service";
import {FilterCategory} from "../../../shared/models/filter-category.model";

@Injectable({providedIn: 'root'})
/**
 * This service handles the filtering functionality.
 */
export class FilterService {

    /**
     * The filters UI structure which are passed to the fuse navigation, so it can render.
     */
    menuData: FuseNavigationItem[];

    private _navigationName: string = 'demo-sidebar-navigation';

    /**
     * The values which will be used for the filtering.
     *
     * @private
     */
    private _filtersArray: string[] = [];

    /**
     * The currently selected filter categories.
     *
     * @private
     */
    private _selectedCategories: string[] = [];

    private _triggerListViewFiltering: Subject<any> = new Subject<any>();

    triggerListViewFiltering$: Observable<any> = this._triggerListViewFiltering.asObservable();

    constructor(private _fuseNavigationService: FuseNavigationService,
                private _apiService: ApiService,
                private _router: Router,
                private _route: ActivatedRoute,
                private _sharedService: SharedService) {

        this.menuData = [
            {
                id: FilterCategories.status,
                title: 'Status',
                subtitle: '',
                type: 'group',
                children: [
                    {
                        id: '-status_any',
                        title: 'Any',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.status
                        }
                    },

                    {
                        id: Status.new,
                        title: 'New',
                        type: 'basic',
                        icon: 'mat_solid:new_releases',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.status
                        }
                    },
                    {
                        id: Status.toReview,
                        title: 'To Review',
                        type: 'basic',
                        icon: 'heroicons_solid:question-mark-circle',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.status
                        }
                    },
                    {
                        id: Status.approved,
                        title: 'Approved',
                        type: 'basic',
                        icon: 'heroicons_solid:check-circle',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.status
                        }
                    },
                    {
                        id: Status.published,
                        title: 'Published',
                        type: 'basic',
                        icon: 'mat_outline:published_with_changes',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.status
                        }
                    },

                    {
                        id: Status.rejected,
                        title: 'Rejected',
                        type: 'basic',
                        icon: 'heroicons_solid:stop',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.status
                        }
                    },
                    {
                        id: Status.bugfixing,
                        title: 'Bugfixing',
                        type: 'basic',
                        icon: 'mat_solid:bug_report',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.status
                        }
                    },
                    {
                        id: Status.invalidSource,
                        title: 'Invalid Source',
                        type: 'basic',
                        icon: 'heroicons_outline:exclamation-circle',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.status
                        }
                    },
                    {
                        id: Status.deleted,
                        title: 'Deleted',
                        type: 'basic',
                        icon: 'mat_outline:delete',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.status
                        }
                    },
                ]
            },
            {
                id: FilterCategories.placement,
                title: 'Placement',
                subtitle: '',
                type: 'collapsable',
                children: [
                    {
                        id: '-placement_any',
                        title: 'Any',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.placement
                        }
                    },
                    {
                        id: '-floor',
                        title: 'Floor',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.placement
                        }
                    },
                    {
                        id: '-wall',
                        title: 'Wall',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.placement
                        }

                    },
                    {
                        id: '-ceiling',
                        title: 'Ceiling',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.placement
                        }

                    },
                ]
            },
            {
                id: FilterCategories.category,
                title: 'Category',
                subtitle: '',
                type: 'collapsable',
                children: [
                    {
                        id: '-category_any',
                        title: 'Any',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.category
                        }
                    },
                    {
                        id: '-WDL',
                        title: 'Wall / Ceiling Light',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.category
                        }
                    },
                    {
                        id: '-HAL',
                        title: 'Pendant Light',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.category
                        }

                    },
                    {
                        id: '-WAL',
                        title: 'Wall Light',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.category
                        }

                    },
                    {
                        id: '-DEL',
                        title: 'Ceiling Light',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.category
                        }

                    },
                    {
                        id: '-STL',
                        title: 'Floor Light',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.category
                        }

                    },
                    {
                        id: '-SPO',
                        title: 'Spot Light',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.category
                        }

                    },
                    {
                        id: '-TIL',
                        title: 'Table Light',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.category
                        }
                    },
                    {
                        id: '-AUL',
                        title: 'Surface-Mounted Light',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.category
                        }

                    },
                    {
                        id: '-SPL',
                        title: 'Mirror Light',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.category
                        }

                    },
                    {
                        id: '-EIL',
                        title: 'Recessed Light',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.category
                        }

                    },
                ]
            },
            {
                id: FilterCategories.processing_status,
                title: 'Processing Status',
                subtitle: '',
                type: 'collapsable',
                children: [
                    {
                        id: '-processing_status_any',
                        title: 'Any',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.processing_status
                        }
                    },
                    {
                        id: '-ready',
                        title: 'Ready',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.processing_status
                        }
                    },
                    {
                        id: '-pending',
                        title: 'Processing',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.processing_status
                        }
                    },
                    {
                        id: '-failed',
                        title: 'Failed',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.processing_status
                        }
                    },
                    {
                        id: '-no_metadata',
                        title: 'Missing Metadata',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.processing_status
                        }
                    },
                ]
            },
            {
                id: FilterCategories.opened_issues,
                title: 'Open Issues',
                type: 'collapsable',
                disabled: true,
                children: [
                    {
                        id: 'clear',
                        title: 'Clear Selection',
                        type: 'basic',
                        icon: 'mat_solid:clear',
                        active: false,
                        function: (): void => {
                            this.unselectCategory(FilterCategories.opened_issues);
                        }
                    },
                    {
                        id: '-has_opened_issues',
                        title: 'Any',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.opened_issues
                        }
                    },

                ]
            },
            {
                id: FilterCategories.closed_issues,
                title: 'Closed Issues',
                type: 'collapsable',
                disabled: true,
                children: [
                    {
                        id: 'clear',
                        title: 'Clear Selection',
                        type: 'basic',
                        icon: 'mat_solid:clear',
                        active: false,
                        function: (): void => {
                            this.unselectCategory(FilterCategories.closed_issues);
                        }
                    },
                    {
                        id: '-has_closed_issues',
                        title: 'Any',
                        type: 'basic',
                        icon: 'heroicons_solid:folder',
                        badge: {
                            title: '',
                            classes: 'px-2 bg-primary text-on-primary rounded-full'
                        },
                        active: false,
                        function: (item): void => {
                            this.addFilter(item);
                        },
                        meta: {
                            parentId: FilterCategories.closed_issues
                        }
                    }
                ]
            }
        ];

    }

    prepareSidebar() {
        this._route.queryParams.pipe(take(1)).subscribe(queryParams => {
            /** These are the categories that we have in our Filters Sidebar. **/
            const filterCategories = Object.values(FilterCategories);

            this._selectedCategories = Object.keys(queryParams)

            for (const filterCategory of filterCategories) {
                /** Here we are working only with the filter parameters. **/
                if (queryParams[filterCategory]) {
                    this._filtersArray.push(queryParams[filterCategory]);
                }
            }

        });
    }

    checkForActiveFilters() {
        this.menuData.forEach(el => {
            const selectedFilter = el.children.find(o => this._filtersArray.includes(this._sharedService.parseTextUsedForFiltering(o.id)));

            if (selectedFilter) {
                selectedFilter.active = true;
            }
        })

        this._triggerListViewFiltering.next(null);
    }

    prepareIssues(issues: any) {
        if (issues.length > 0) {


            const openedIssuesSidebarObjectIndex = this.menuData.findIndex(el => el.id === FilterCategories.opened_issues);
            const closedIssuesSidebarObjectIndex = this.menuData.findIndex(el => el.id === FilterCategories.closed_issues)
            const openedIssuesNavigationItem = this.menuData[openedIssuesSidebarObjectIndex];
            const closedIssuesNavigationItem = this.menuData[closedIssuesSidebarObjectIndex];

            for (const item of issues) {
                const openedIssueObject = new FilterCategory(
                    `-${item.code}`,
                    `${item.code}`,
                    'basic',
                    `${item.title}`,
                    {
                        title: '',
                        classes: 'px-2 bg-primary text-on-primary rounded-full'
                    },
                    false,
                    {parentId: FilterCategories.opened_issues}
                )

                openedIssueObject.function = () => this.addFilter(openedIssueObject);
                openedIssuesNavigationItem.disabled = false;
                openedIssuesNavigationItem.children.push(openedIssueObject);


                const closedIssueObject = new FilterCategory(
                    `-${item.code}_fixed`,
                    `${item.code}`,
                    'basic',
                    `${item.title}`,
                    {
                        title: '',
                        classes: 'px-2 bg-primary text-on-primary rounded-full'
                    },
                    false,
                    {parentId: FilterCategories.closed_issues}
                )

                closedIssueObject.function = () => this.addFilter(closedIssueObject)
                closedIssuesNavigationItem.disabled = false;
                closedIssuesNavigationItem.children.push(closedIssueObject);
            }

        }
    }

    get filtersArray(): string[] {
        return this._filtersArray;
    }

    /**
     * Clear the selected category.
     * @param categoryId - ID of the category that we want to unselect.
     */
    unselectCategory(categoryId: string): void {
        const category = this.menuData.find(el => el.id === categoryId);
        const selectedFilter = category.children.find(child => child.active === true);

        if (selectedFilter) {
            selectedFilter.active = false;

            this._router.navigate([], {
                queryParams: {
                    [categoryId]: null,
                },
                queryParamsHandling: 'merge'
            })

            this.removeFromFilterArray(this._sharedService.parseTextUsedForFiltering(selectedFilter.id));
        }
    }

    /**
     * Resets the selected filters to default state.
     */
    resetAllFilters(): void {
        this._filtersArray = [];
        this._triggerListViewFiltering.next(null);

        for (const item of this.menuData) {
            for (const child of item.children) {
                child.active = false;
            }
        }

        const resetQueryParams = {};

        for (const category of Object.values(FilterCategories)) {
            resetQueryParams[category] = null;
        }

        this._router.navigate([], {
            queryParams: resetQueryParams,
            queryParamsHandling: 'merge'
        })
    }

    addFilter(item: FuseNavigationItem): void {
        const id = item.meta.parentId;

        this._router.navigate(
            [],
            {
                queryParams: {
                    [id]: this._sharedService.parseTextUsedForFiltering(item.id)
                },
                queryParamsHandling: 'merge'
            }
        );

        if (this._selectedCategories.length > 1) {
            this._selectedCategories.pop();
        }

        if (!this._selectedCategories.includes(id)) {
            this._selectedCategories.push(id);
        }

        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(this._navigationName);
        this.menuData.find(el => el.id === id).children
            .forEach((el) => {
                if (el.id !== item.id) {
                    el.active = false;
                    this.removeFromFilterArray(this._sharedService.parseTextUsedForFiltering(el.id));
                } else {
                    el.active = true;
                    if (el.id) {
                        this.addToFilterArray(this._sharedService.parseTextUsedForFiltering(el.id));
                    }
                }
            });

        navComponent.refresh();
    }

    addToFilterArray(filterFormat: string): void {
        if (!this._filtersArray.includes(filterFormat)) {
            this._filtersArray.push(filterFormat);
            this._filtersArray = [...this._filtersArray];
            this._triggerListViewFiltering.next(null);
        }
    }

    removeFromFilterArray(filterFormat: string): void {
        if (this._filtersArray.includes(filterFormat)) {
            this._filtersArray.splice(this._filtersArray.indexOf(filterFormat), 1);
            this._filtersArray = [...this._filtersArray];
            this._triggerListViewFiltering.next(null);
        }
    }

    parseBadgeTitleNumbers(allElementsArray: any, checkCategories: boolean): void {

        if (checkCategories) {
            this.menuData.forEach((item) => {
                if (!this._selectedCategories.includes(item.id) && item.id !== FilterCategories.status) {
                    this.setBadgeNumbers(item, allElementsArray);
                }
            });
        } else {
            this.menuData.forEach((item) => {
                this.setBadgeNumbers(item, allElementsArray);
            });
        }

    }

    setBadgeNumbers(item: FuseNavigationItem, allElementsArray: CockpitItem[]) {
        // These are the ids of the 'Any' badges in the sidebar. Here we don't need any filtering, we can just show the number of all products.
        const idsToSkip = ['status_any', 'placement_any', 'category_any', 'processing_status_any'];

        // Filter the children of the top elements/objects in menuData.
        item.children.forEach((el) => {
            if (el.id) {
                const title = allElementsArray.filter((f) => {
                    if (idsToSkip.includes(this._sharedService.parseTextUsedForFiltering(el.id))) {
                        return true;
                    } else {
                        return Object.values(f.filteringProperties).includes(this._sharedService.parseTextUsedForFiltering(el.id));
                    }
                }).length;

                setTimeout(() => this.updateBadgeTitle(el.id, title));
            }
        });
    }

    /**
     * Update badge title
     *
     * @param itemId
     * @param title
     */
    updateBadgeTitle(itemId, title): void {
        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(this._navigationName);
        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the navigation item, update the badge and refresh the component
        const navigation = navComponent.navigation;
        const item = this._fuseNavigationService.getItem(itemId, navigation);
        if (item.badge) {
            item.badge.title = title;
        }
        navComponent.refresh();
    }

}
