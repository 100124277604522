import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {IssuesDialogComponent} from './issues-dialog/issues-dialog.component';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Issue} from '../../interfaces/interfaces';

@Injectable({providedIn: 'root'})
export class IssuesService {

    private _newIssuePillsArray = [];

    private _deleteIssuePillsArray = [];

    private _newIssue: Subject<Issue> = new Subject<Issue>();
    newIssue$: Observable<Issue> = this._newIssue.asObservable();

    private _deleteIssue: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    deleteIssue$: Observable<any> = this._deleteIssue.asObservable();

    private _reopenIssue: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    reopenIssue$: Observable<any> = this._reopenIssue.asObservable();

    private _resolveIssue: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    resolveIssue$: Observable<any> = this._resolveIssue.asObservable();

    constructor(private _dialog: MatDialog) {
    }

    get deleteIssuePillsArray(): any[] {
        return this._deleteIssuePillsArray;
    }

    get issuePillsArray(): any[] {
        return this._newIssuePillsArray;
    }

    newIssue(issue: Issue): void {
        this._newIssue.next(issue);
    }

    newIssuePill(issue: Issue): void {
        this._newIssuePillsArray.push(issue);
    }

    deleteIssuePill(issue: Issue): void {
        this._deleteIssuePillsArray.push(issue);
    }

    deleteIssue(issue: any): void {
        this._deleteIssue.next(issue);
    }

    reopenIssue(issue: any): void {
        this._reopenIssue.next(issue);
    }

    resolveIssue(issue: any): void {
        this._resolveIssue.next(issue);
    }

    /**
     * Opens the Upload File popup dialog
     */
    openDialog(smartvu: any, issues: any): void {
        const dialogRef = this._dialog.open(IssuesDialogComponent, {
            width: '600px',
            data: {
                smartvu: smartvu,
                issues: issues
            }
        });
    }
}
