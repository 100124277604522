import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MainService} from '../../../modules/main/services/main.service';
import {Subject, takeUntil} from 'rxjs';

@Component({
    selector: 'search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {

    @Output() sendSearchValue: EventEmitter<any> = new EventEmitter<any>();

    @Input() useSearchValueSubscription: boolean;
    searchValue: string = '';

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private _mainService: MainService) {
    }

    ngOnInit(): void {
        if (this.useSearchValueSubscription) {
            this._mainService.searchValue$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(searchValue => this.searchValue = searchValue);
            this.sendSearchValue.emit(this.searchValue);
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    onChange(value): void {
        this.searchValue = value;
        if (this.useSearchValueSubscription) {
            this._mainService.searchValue(value);
        }
        this.sendSearchValue.emit(value);
    }

}
