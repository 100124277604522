import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IssueType} from '../../../interfaces/interfaces';
import {ApiService} from '../../../services/api.service';
import {Subject, takeUntil} from "rxjs";
import {IssuesService} from "../issues.service";
import {CockpitItemModel} from "../../../models/cockpit-item.model";

@Component({
    selector: 'app-issues-dialog',
    templateUrl: './issues-dialog.component.html',
    styleUrls: ['./issues-dialog.component.scss']
})
export class IssuesDialogComponent implements OnInit, OnDestroy {

    textAreaValue: string;
    availableIssues: Array<IssueType> = [];
    isLoading: boolean = true;
    smartvu: CockpitItemModel;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(public dialogRef: MatDialogRef<IssuesDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private _apiService: ApiService,
                private _issuesService: IssuesService) {
    }

    ngOnInit(): void {
        this._apiService.allIssues$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                {
                    next: (issueTypes) => {
                        this.availableIssues = issueTypes.filter(issueType => !this.data.issues.some(el => el.code === issueType.code));
                    }
                    ,
                    error: () => {
                    },
                    complete: () => this.isLoading = false
                });

        this.smartvu = this.data.smartvu;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    createIssue(selectedType: IssueType): void {

        if (selectedType) {
            const issue = {
                ownerToken: this.smartvu.smartVuToken,
                code: selectedType.code
            };

            if (this.textAreaValue) {
                issue['comment'] = this.textAreaValue;
            }

            this._apiService.addIssueToSmartVu(issue).subscribe(
                response => {
                    const issue = {
                        code: response.issue.code,
                        comment: response.comment,
                        ownerToken: response.owner_token,
                        fixed: response.fixed,
                        issueType: {
                            code: response.issue.code,
                            title: response.issue.title,
                            level: response.issue.level,
                            description: response.issue.description
                        }
                    };

                    this._issuesService.newIssue(issue);
                    this._issuesService.newIssuePill(issue);
                }
            );
        }

    }

}
