import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'MenuSearchPipe'
})

export class DropdownMenuSearchPipe implements PipeTransform {
    transform(value: any, args?: any): any {

        if(!value){return null;}
        if(!args){return value;}

        args = args.toLowerCase();

        return value.filter(item => JSON.stringify(`[${item.code}] - ${item.title}`).toLowerCase().includes(args));
    }
}
