<div class="flex justify-center items-center flex-col w-full">
    <div class="border-dashed border-2 rounded-lg flex justify-center items-center flex-col gap-3 p-5">
        <button mat-fab color="warn" class="mb-3">
            <mat-icon>lock</mat-icon>
        </button>

        <h3 class="font-bold text-2xl">Access Denied</h3>

        <p>You don't have the right permissions to preview this content.</p>

        <button mat-raised-button (click)="logout()" class="mt-2">Try again</button>
    </div>
</div>
