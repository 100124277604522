import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from './material.module';
import {SearchBarComponent} from './components/search-bar/search-bar.component';
import {IssuesComponent} from './components/issues/issues.component';
import {SimpleMenuComponent} from './components/menus/simple-menu/simple-menu.component';
import {BaseMenuComponent} from './components/menus/base-menu.component';
import {DropdownMenuSearchPipe} from './pipes/dropdown-menu-search-pipe';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {IssuesDialogComponent} from './components/issues/issues-dialog/issues-dialog.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatGridListModule} from '@angular/material/grid-list';
import {RejectDialogComponent} from './components/issues/reject-dialog/reject-dialog.component';
import {QrCodeComponent} from './components/qr-code/qr-code.component';
import {QRCodeModule} from 'angularx-qrcode';
import {FuseCardModule} from '../../@fuse/components/card';
import {FuseAlertModule} from "../../@fuse/components/alert";


@NgModule({
    declarations: [
        SearchBarComponent,
        IssuesComponent,
        SimpleMenuComponent,
        BaseMenuComponent,
        DropdownMenuSearchPipe,
        IssuesDialogComponent,
        RejectDialogComponent,
        QrCodeComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        FuseCardModule,
        NgxMatSelectSearchModule,
        MatButtonToggleModule,
        MatGridListModule,
        QRCodeModule,
        FuseAlertModule
    ],
    exports: [
        MaterialModule,
        CommonModule,
        SearchBarComponent,
        IssuesComponent,
        BaseMenuComponent,
        SimpleMenuComponent,
        IssuesDialogComponent,
        RejectDialogComponent,
        QrCodeComponent
    ]
})
export class SharedModule {
}
