import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'base-menu-component',
    template: '',
})
export class BaseMenuComponent implements OnInit {

    @Input() hasSearchBar: boolean;
    @Input() hasImagePreview: boolean;
    @Input() isMultiselect: boolean;

    /**
     * The input in the search bar.
     */
    searchValue: string;
    /**
     * The selected object from the menu
     */
    selectedItem: any;

    constructor() {
    }

    ngOnInit(): void {
    }

    /**
     * This method is triggered on keypress in the menu's search bar.
     * On every keypress the options in the menu are filtered from the filter pipe.
     *
     * @param value - user input coming from the search bar
     */
    onSearchValue(value: string): void {
        this.searchValue = value;
    };

    /**
     * This method is triggered when a selection in the menu is changed
     *
     * @param value
     */
    onSelectionChange(value: any): void {
    }

}
