import {CustomJson, FilteringProperties, Group} from "../interfaces/interfaces";
import {SmartVu} from "../interfaces/smartvu.interface";
import {CockpitItemLinks, ProcessingStatus, Status, UploadType} from "../enums/enums";
import {environment} from "../../../environments/environment";
import {convertGroups, getSmartVuUploadType, parseTextUsedForFiltering} from "../utils/helpers";

export class CockpitItemModel {
    private _customJson: CustomJson;
    private _embedLink: string;
    private _failed: boolean;
    private _fbxDownload: string;
    private _filteringProperties: FilteringProperties;
    private _groups: Group[];
    private _issues: any[];
    private _modelLink: string;
    private _modelTitle: string;
    private _position: number;
    private _previewImageUrl: string;
    private _processingStatus: string;
    private _shareLink: string;
    private _smartVuLink: string;
    private _smartVuToken: string;
    private _title: string;
    private _uploadType: string;
    private _shareCode: string;
    private _downloadType: string;
    private _lastUpdatedAt: string;
    private _ftpUpdatedAt: string;
    private _modelToken: string;
    private _modelImageUrl: string;
    private _manifest: any;
    private _project: string;
    private _duplicate: boolean;
    private _duplicates: Array<any>;
    private _hasMetadata: string;


    private readonly _contentManifest: any;

    constructor(smartVu: SmartVu, position?: number, issues?: any) {
        this._contentManifest = smartVu.manifest.find((manifest: any) => manifest.item_type === 'any3d_scene');

        this.modelImageUrl = this._contentManifest?.preview_image_url;
        this.manifest = this._contentManifest;
        this.project = smartVu.project;
        this.shareCode = smartVu.share_code;
        this.duplicate = smartVu.duplicate;
        this.duplicates = smartVu.duplicates;
        this.customJson = this.parseCustomJson(smartVu);
        this.title = smartVu.title;
        this.shareCode = smartVu.share_code;
        this.smartVuToken = smartVu.token;
        this.processingStatus = smartVu.processing_status;
        this.position = position;
        this.fbxDownload = this.parseFbxDownload(smartVu);
        this.embedLink = this.parseLink(smartVu, CockpitItemLinks.embed);
        this.shareLink = this.parseLink(smartVu, CockpitItemLinks.share);
        this.smartVuLink = this.parseLink(smartVu, CockpitItemLinks.studio);
        this.modelLink = this.parseLink(smartVu, CockpitItemLinks.model);
        this.failed = smartVu.processing_status === ProcessingStatus.failed;
        this.modelTitle = this._contentManifest?.title;
        this.modelToken = this._contentManifest?.token;
        this.previewImageUrl = this.setPreviewImage();
        this.groups = convertGroups(smartVu.groups);
        this.uploadType = getSmartVuUploadType(this.customJson);
        this.downloadType = this.customJson.file_inclusion;
        this.lastUpdatedAt = this.setUpdatedAt(smartVu.updated_at, this._contentManifest?.updated_at);
        this.ftpUpdatedAt = smartVu.ftp_updated_at;
        this.hasMetadata = this.customJson.metadata ? 'has_metadata': 'no_metadata';
        this.filteringProperties = this.setFilteringProperties(this._smartVuToken, this._processingStatus, this._groups, issues);

    }

    setIssues(issues: any): void {
        const productIssues = this.getIssuesForOwner(this._smartVuToken, issues);
        this.issues = productIssues;

        // If the product has issues, map them into the filteringProperties object. This is needed for the filtering.
        if (productIssues.length > 0) {
            productIssues.map((item, index) => {
                if (!item.fixed) {
                    this._filteringProperties['hasOpenedIssues'] = 'has_opened_issues';
                    this._filteringProperties['issue' + (index + 1)] = item.issue.code.toLowerCase();
                } else {
                    this._filteringProperties['hasClosedIssues'] = 'has_closed_issues';
                    this._filteringProperties['issue' + (index + 1)] = `${item.issue.code.toLowerCase()}_fixed`;
                }
            });
        }
    }


    /** Private helpers **/

    // TODO: create FilteringProperties interface
    private setFilteringProperties(smartVuToken: string, smartVuProcessing: string, smartVuGroups: any, issues: any): FilteringProperties {

        let filteringProperties: FilteringProperties = {
            uploadType: this.customJson.source_file_updated_at ? UploadType.ftp : UploadType.manual,
            category: this.customJson.metadata?.type_code?.toLowerCase(),
            placement: this.getPlacement(this.customJson.metadata?.type_code),
            processingStatus: this.getProcessingStatus(smartVuProcessing),
            hasMetadata: this.hasMetadata
        };

        const groups = smartVuGroups;

        // If the product is into group/groups, map them to the filteringProperties object.
        if (groups.length > 0) {
            groups.map((group, index) => filteringProperties['group' + (index + 1)] = parseTextUsedForFiltering(group.title));
        } else if (!(!this._contentManifest && !smartVuProcessing)) {
            filteringProperties['group'] = parseTextUsedForFiltering(Status.new)
        }

        return filteringProperties;
    }


    private getIssuesForOwner(smartVuToken: string, issues: any): any {
        const issuesArray = [];

        if (issues) {
            for (const issue of issues) {
                if (issue.owners.length > 0) {
                    for (const owner of issue.owners) {
                        if (owner.owner_token === smartVuToken) {
                            issuesArray.push(owner);
                        }
                    }
                }
            }
        }

        return issuesArray;
    }

    // TODO: refactor - don't use hardcoded strings
    private getPlacement(typeCode): string | null {
        const ceilingCodes = ['WDL', 'HAL', 'DEL', 'SPO', 'AUL', 'EIL'];
        const wallCodes = ['WAL', 'SPL'];
        const floorCodes = ['STL', 'TIL'];

        switch (true) {
            case ceilingCodes.includes(typeCode):
                return 'ceiling';
            case wallCodes.includes(typeCode):
                return 'wall';
            case floorCodes.includes(typeCode):
                return 'floor';
            default:
                return null;
        }
    }

    private getProcessingStatus(smartVuProcessing: string): string {
        return smartVuProcessing;
    }

    private parseLink(smartVu: SmartVu, linkType: string): string {
        switch (true) {
            case linkType === CockpitItemLinks.embed:
                return smartVu.share_code ? `${CockpitItemLinks.embed}/${smartVu.share_code}` : null;
            case linkType === CockpitItemLinks.share:
                return smartVu.share_code ? `${CockpitItemLinks.share}/${smartVu.share_code}` : null;
            case linkType === CockpitItemLinks.model:

                return `${environment.studioLink}/b74a09023d217e0caad92c69/projects/${smartVu.project}/items/${this._contentManifest?.token}`;
            case linkType === CockpitItemLinks.studio:
                return `${environment.studioLink}/b74a09023d217e0caad92c69/projects/${smartVu.project}/smartvus/${smartVu.token}`
        }
    }

    private parseCustomJson(smartVu: SmartVu): any {
        return smartVu.features.find((feature: any) => feature.token === smartVu.initial_feature).custom_json;
    }

    private parseFbxDownload(smartVu: SmartVu): string {
        return smartVu.manifest?.properties?.additional_formats['fbx'] ? `${environment.studioLink}/b74a09023d217e0caad92c69/projects/${smartVu.project}/items/${this._contentManifest.token}/download_format?type=fbx` : null;
    }

    private setPreviewImage(): string {
        let imageUrl = 'assets/images/no-render-image.png';
        const productPhotos = this.customJson.metadata?.product_photos;
        if (productPhotos != null) {
            imageUrl = productPhotos[0];
        }

        return imageUrl;
    }

    private setUpdatedAt(value1, value2) {
        if (value1 === undefined && value2) {
            return value2;
        }

        if (value2 === undefined && value1) {
            return value1;
        }

        const date1 = new Date(value1);
        const date2 = new Date(value2);


        if (date1 > date2) {
            return value1;
        }

        return value2;
    }

    /** Getters and Setters **/

    get fbxDownload(): string {
        return this._fbxDownload;
    }

    set fbxDownload(value: string) {
        this._fbxDownload = value;
    }

    get customJson(): any {
        return this._customJson;
    }

    set customJson(value: any) {
        this._customJson = value;
    }

    get embedLink(): string {
        return this._embedLink;
    }

    set embedLink(value: string) {
        this._embedLink = value;
    }

    get failed(): boolean {
        return this._failed;
    }

    set failed(value: boolean) {
        this._failed = value;
    }

    get filteringProperties(): FilteringProperties {
        return this._filteringProperties;
    }

    set filteringProperties(value: FilteringProperties) {
        this._filteringProperties = value;
    }

    get groups(): Group[] {
        return this._groups;
    }

    set groups(value: Group[]) {
        this._groups = value;
    }

    get issues(): any[] {
        return this._issues;
    }

    set issues(value: any[]) {
        this._issues = value;
    }

    get modelTitle(): string {
        return this._modelTitle;
    }

    set modelTitle(value: string) {
        this._modelTitle = value;
    }

    get position(): number {
        return this._position;
    }

    set position(value: number) {
        this._position = value;
    }

    get previewImageUrl(): string {
        return this._previewImageUrl;
    }

    set previewImageUrl(value: string) {
        this._previewImageUrl = value;
    }

    get processingStatus(): string {
        return this._processingStatus;
    }

    set processingStatus(value: string) {
        this._processingStatus = value;
    }

    get shareLink(): string {
        return this._shareLink;
    }

    set shareLink(value: string) {
        this._shareLink = value;
    }

    get smartVuLink(): string {
        return this._smartVuLink;
    }

    set smartVuLink(value: string) {
        this._smartVuLink = value;
    }

    get smartVuToken(): string {
        return this._smartVuToken;
    }

    set smartVuToken(value: string) {
        this._smartVuToken = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get uploadType(): string {
        return this._uploadType;
    }

    set uploadType(value: string) {
        this._uploadType = value;
    }

    get modelLink(): string {
        return this._modelLink;
    }

    set modelLink(value: string) {
        this._modelLink = value;
    }

    get shareCode(): string {
        return this._shareCode;
    }

    set shareCode(value: string) {
        this._shareCode = value;
    }

    set downloadType(value: string) {
        this._downloadType = value;
    }

    get downloadType(): string {
        return this._downloadType;
    }

    get lastUpdatedAt(): string {
        return this._lastUpdatedAt;
    }

    set lastUpdatedAt(value: string) {
        this._lastUpdatedAt = value;
    }

    get ftpUpdatedAt(): string {
        return this._ftpUpdatedAt;
    }

    set ftpUpdatedAt(value: string) {
        this._ftpUpdatedAt = value;
    }

    get modelToken(): string {
        return this._modelToken;
    }

    set modelToken(token: string) {
        this._modelToken = token;
    }

    get modelImageUrl(): string {
        return this._modelImageUrl;
    }

    set modelImageUrl(value: string) {
        this._modelImageUrl = value;
    }

    get manifest(): any {
        return this._manifest;
    }

    set manifest(value: any) {
        this._manifest = value;
    }

    get project(): string {
        return this._project;
    }

    set project(value: string) {
        this._project = value;
    }

    get duplicate(): boolean {
        return this._duplicate;
    }

    set duplicate(value: boolean) {
        this._duplicate = value;
    }

    get duplicates(): Array<any> {
        return this._duplicates;
    }

    set duplicates(value: Array<any>) {
        this._duplicates = value;
    }

    get hasMetadata(): string {
        return this._hasMetadata;
    }

    set hasMetadata(value: string) {
        this._hasMetadata = value;
    }
}
