import {createFeatureSelector, createSelector} from '@ngrx/store';

export const selectSmartvus = createFeatureSelector<any>('smartvus');

export const selectSmartVuItems = createSelector(
    selectSmartvus,
    (state: any) => state.smartvus
);

export const selectSmartVusChunk = createSelector(
    selectSmartvus,
    (state: any) => state.currentChunk
);

export const selectSmartVuItem = (props: { token: string }): any =>
    createSelector(selectSmartVuItems, smartvus => smartvus.find(smartvu => smartvu.smartVuToken === props.token));
