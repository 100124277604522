import {Route} from '@angular/router';
import {LayoutComponent} from 'app/layout/layout.component';
import {AccessGuard} from 'app/shared/guards/access.guard';
import {AuthorizationComponent} from './modules/authorization/authorization.component';
import {CallbackComponent} from './modules/authorization/callback.component';
import {ErrorComponent} from "./modules/error/error.component";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [


    {path: '', redirectTo: 'products', pathMatch: 'full'},
    {path: 'callback', component: CallbackComponent},

    {
        path: 'authorization',
        component: AuthorizationComponent
    },
    {
        path: '',
        canActivate: [AccessGuard],
        component: LayoutComponent,
        children: [
            {
                path: 'products',
                loadChildren: () => import('app/modules/main/main.module').then(m => m.MainModule)
            },
            {
                path: 'products/:id',
                loadChildren: () => import('app/modules/product-details/product-details.module').then(m => m.ProductDetailsModule)
            }
        ]
    },
    {
        path: 'error',
        component: ErrorComponent,
    }
];
