import {Component, Inject, OnInit} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import * as csv from 'csvtojson';
import {MetadataUploadService} from './services/metadata-upload.service';
import {MetadataObject} from './metadata-upload.types';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MainService} from '../main/services/main.service';
import {Status} from '../../shared/enums/enums';
import {ApiService} from "../../shared/services/api.service";
import {getSmartVuUploadType} from "../../shared/utils/helpers";


@Component({
    selector: 'upload-file-component',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
    uploader: FileUploader;
    hasBaseDropZoneOver: boolean;
    hasAnotherDropZoneOver: boolean;
    response: string;
    mappedMetadata: MetadataObject[];
    fileUploaded: boolean;
    fileType: string;

    constructor(public metadataUploadService: MetadataUploadService,
                private mainService: MainService,
                private apiService: ApiService,
                @Inject(MAT_DIALOG_DATA) public data: any
    ) {

        this.uploader = new FileUploader({
            disableMultipart: true,
            formatDataFunctionIsAsync: true,
            formatDataFunction: async item => new Promise((resolve) => {
                resolve({
                    name: item._file.name,
                    length: item._file.size,
                    contentType: item._file.type,
                    date: new Date()
                });
            })
        });

        this.hasBaseDropZoneOver = false;
        this.hasAnotherDropZoneOver = false;

        this.response = '';

        this.uploader.response.subscribe(res => console.log(res));
    }

    ngOnInit(): void {
    }

    handleFileSelect(evt): void {
        const files = evt.target?.files || evt;
        const file = files[0];
        this.fileType = file.type;
        const reader = new FileReader();
        reader.readAsText(file);

        if (this.fileType === 'text/csv') {
            reader.onload = (event: any) => {

                csv({

                    colParser: {
                        'Gehäusefarbe Code': item => null,
                        'Batterie Code': item => null,
                        'Netzspannung Code': item => null,
                        'Schutzart Code': item => null,
                        'Betriebsspannung Code': item => null,
                        'Gehäusematerial Code': item => null,
                        'Glas-/Schirmfarbe Code': item => null,
                        'Battery Code': item => null,
                        'Switch Type Code': item => null,
                        'Identifier': item => null,
                        'Couleur principale Code': item => null,
                        'Matière principale Code': item => null,
                        'Schalterart Code': item => null,
                        'Matière du verre/de l’abat-jour Code': item => null,
                        'Glas-/Schirmmaterial Code': item => null,
                        'Glass/Shade Material Code': item => null,
                        'field135': item => this.metadataUploadService.parseProductPhotos(item),
                        'field136': item => this.metadataUploadService.parseProductPhotos(item),
                        'field138': item => this.metadataUploadService.parseProductPhotos(item),
                        'field139': item => this.metadataUploadService.parseProductPhotos(item),
                        'field140': item => this.metadataUploadService.parseProductPhotos(item),
                        'field141': item => this.metadataUploadService.parseProductPhotos(item),
                        'field142': item => this.metadataUploadService.parseProductPhotos(item),
                        'field143': item => this.metadataUploadService.parseProductPhotos(item),
                    },
                    ignoreEmpty: true,
                    delimiter: ';',
                    noheader: false,
                    flatKeys: true
                })
                    .fromString(event.target.result)
                    .then((jsonObj) => {
                        const enKeys = jsonObj[0];
                        const frKeys = jsonObj[1];


                        const parsedCSV: any[] = jsonObj.map(obj => {

                            const metadataObject = {
                                de: {},
                                en: {},
                                fr: {}
                            };


                            for (const key in enKeys) {
                                if (obj[key]) {
                                    metadataObject['en'][enKeys[key]] = obj[key];
                                }
                            }

                            for (const key in frKeys) {
                                if (obj[key]) {
                                    metadataObject['fr'][frKeys[key]] = obj[key];
                                }

                            }

                            for (const key in obj) {
                                if (obj[key] && !key.includes('field')) {
                                    metadataObject['de'][key] = obj[key];
                                }
                            }

                            metadataObject['product_photos'] = this.metadataUploadService.getProductPhotos(obj.field135, obj.field136, obj.field138, obj.field139, obj.field140, obj.field141, obj.field142, obj.field143)

                            return metadataObject;

                        });

                        // disable the first six objects as they are not needed
                        parsedCSV.splice(0, 6);


                        // filter the objects - return only the metadata objects for the existing SmartVu's in Studio
                        this.mappedMetadata = this.filterMetadata(parsedCSV);



                        //const test = this.mappedMetadata.filter(el => el['en']['Serial name'] === 'MELITO');
                        // console.log(test)
                        // trigger metadata job
                        this.apiService.startMetadataJob(this.mappedMetadata).subscribe(res => console.log(res));

                    });
            };
        }

        reader.onloadend = () => {
            if (this.fileType === 'text/csv') {
                this.fileUploaded = true;
                this.metadataUploadService.setIsJobProcessing(true);
            }
        };
    }

    /**
     * Filtering the Metadata Objects received from the .csv file and returning a filtered array which can be passed to the middleware server.
     *
     * @param parsedCSV - All parsed objects from the .csv file.
     */
    filterMetadata(parsedCSV: MetadataObject[]): MetadataObject[] {

        const filteredArray = [];
        let metadataObject = {};

        for (const item of this.data.allSmartVus) {
            let name;
            let materialNumber;
            let typeCode


            if (item) {
                metadataObject = parsedCSV.find(el => {
                    name = el['de']['Serienname'];
                    materialNumber = el['de']['Materialnummer'];
                    typeCode = el['de']['Type Code']
                    const title = `${name} (${materialNumber})`
                    return title === item.title || materialNumber === item.title
                });

                if (metadataObject && item.token && typeCode) {
                    const initialFeatureObject = item.features.find((feature: any) => feature.token === item.initial_feature);

                    filteredArray.push({
                        smartvu_token: item.token,
                        upload_type: getSmartVuUploadType(initialFeatureObject.custom_json),
                        groups_to_set: this.metadataUploadService.groupsToSet(item.groups, this.mainService.getGroups()),
                        is_published: this.metadataUploadService.isInStatus([Status.published], item.groups),
                        type_code: typeCode,
                        material_number: materialNumber,
                        serial_name: name,
                        ...metadataObject
                    });
                }
            }
        }

        return filteredArray;
    }

}
