import {Injectable} from "@angular/core";
import {CockpitItemModel} from "../../../../shared/models/cockpit-item.model";
import {getSmartVuUploadType} from "../../../../shared/utils/helpers";
import {Status} from "../../../../shared/enums/enums";

@Injectable({providedIn: 'root'})
export class SummaryService{
    inReviewCounter: number = 0;
    acceptedCounter: number = 0;
    rejectedCounter: number = 0;
    autoUploadCounter: number = 0;
    manualUploadCounter: number = 0;
    constructor() {
    }

    /**
     * This function parses the allSmartVus array and
     * sets the counters which we display in the view of this component.
     *
     * @param allSmartVus - An array with all the SmartVus.
     */
    setSummaryCounters(allSmartVus: CockpitItemModel[]): void {
        for (const item of allSmartVus) {

            switch (true) {
                case getSmartVuUploadType(item.customJson) === 'ftp':
                    this.autoUploadCounter++;
                    break;
                case getSmartVuUploadType(item.customJson) === 'manual':
                    this.manualUploadCounter++;
                    break;
            }

            switch (true) {
                case this.groupIsInStatus([Status.published], item.groups):
                    this.acceptedCounter++;
                    break;
                case this.groupIsInStatus([Status.toReview], item.groups):
                    this.inReviewCounter++;
                    break;
                case !this.groupIsInStatus([Status.published], item.groups):
                    this.rejectedCounter++;
                    break;
                default:
                    break;
            }


        }
    }

    private groupIsInStatus(status: Status[], groups: any[]): boolean {
        return groups.some(el => status.includes(el.title));
    }
}
