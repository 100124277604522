import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthorizationComponent} from './authorization.component';
import {MaterialModule} from '../../shared/material.module';
import {CallbackComponent} from './callback.component';




@NgModule({
    declarations: [
        AuthorizationComponent,
        CallbackComponent
    ],
    imports: [
        CommonModule,
        MaterialModule
    ]
})
export class AuthorizationModule {
}
