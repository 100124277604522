import {IconsMockApi} from 'app/mock-api/ui/icons/api';
import {NavigationMockApi} from 'app/mock-api/common/navigation/api';
import {SearchMockApi} from 'app/mock-api/common/search/api';
import {UserMockApi} from 'app/mock-api/common/user/api';

export const mockApiServices = [
    IconsMockApi,
    NavigationMockApi,
    SearchMockApi,
    UserMockApi
];
