import {Injectable} from '@angular/core';
import {cloneDeep} from 'lodash-es';
import {FuseNavigationItem, FuseNavigationService} from '@fuse/components/navigation';
import {FuseMockApiService} from '@fuse/lib/mock-api';
import {defaultNavigation} from 'app/mock-api/common/navigation/data';

@Injectable({
    providedIn: 'root'
})
export class SearchMockApi {
    private readonly _defaultNavigation: FuseNavigationItem[] = defaultNavigation;
    private readonly _models: any[] = [
        {
            title: 'Test Model',
            uploadFormat: 'Vuframe 3D (Unity 3D Export)',
            name: 'Hydrogen',
            weight: 1.0079,
            fileSize: '100 MB',
            versions: '5 Versions',
            lastChanged: 'January 11, 2022 17:52'
        },
        {
            title: 'Test File',
            fileSize: '100 MB',
            uploadFormat: 'Vuframe 3D (Unity 3D Export)',
            name: 'Helium',
            weight: 4.0026,
            versions: '5 Versions',
            lastChanged: 'January 11, 2022 17:52'
        },
        {
            title: 'Anniversary-Kettle',
            uploadFormat: 'Vuframe 3D (Unity 3D Export)',
            name: 'Lithium',
            weight: 6.941,
            fileSize: '100 MB',
            versions: '5 Versions',
            lastChanged: 'January 11, 2022 17:52'
        },
        {
            title: 'Anniversary',
            uploadFormat: 'Vuframe 3D (Unity 3D Export)',
            name: 'Beryllium',
            weight: 9.0122,
            fileSize: '100 MB',
            versions: '5 Versions',
            lastChanged: 'January 11, 2022 17:52'
        },
        {
            title: 'Anniversary-Kettle',
            uploadFormat: 'Vuframe 3D (Unity 3D Export)',
            name: 'Boron',
            weight: 10.811,
            fileSize: '100 MB',
            versions: '5 Versions',
            lastChanged: 'January 11, 2022 17:52'
        },
        {
            title: 'Anniversary-Kettle',
            uploadFormat: 'Vuframe 3D (Unity 3D Export)',
            name: 'Carbon',
            weight: 12.0107,
            fileSize: '100 MB',
            versions: '5 Versions',
            lastChanged: 'January 11, 2022 17:52'
        },
        {
            title: 'Anniversary-Kettle',
            uploadFormat: 'Vuframe 3D (Unity 3D Export)',
            name: 'Nitrogen',
            weight: 14.0067,
            fileSize: '100 MB',
            versions: '5 Versions',
            lastChanged: 'January 11, 2022 17:52'
        },
        {
            title: 'Anniversary',
            uploadFormat: 'Vuframe 3D (Unity 3D Export)',
            name: 'Oxygen',
            weight: 15.9994,
            fileSize: '100 MB',
            versions: '5 Versions',
            lastChanged: 'January 11, 2022 17:52'
        },
        {
            title: 'Anniversary-Kettle',
            uploadFormat: 'Vuframe 3D (Unity 3D Export)',
            name: 'Fluorine',
            weight: 18.9984,
            fileSize: '100 MB',
            versions: '5 Versions',
            lastChanged: 'January 11, 2022 17:52'
        },
        {
            title: 'Anniversary-Kettle',
            uploadFormat: 'Vuframe 3D (Unity 3D Export)',
            name: 'Neon',
            weight: 20.1797,
            fileSize: '100 MB',
            versions: '5 Versions',
            lastChanged: 'January 11, 2022 17:52'
        },
    ];


    /**
     * Constructor
     */
    constructor(
        private _fuseMockApiService: FuseMockApiService,
        private _fuseNavigationService: FuseNavigationService
    ) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // Get the flat navigation and store it
        const flatNavigation = this._fuseNavigationService.getFlatNavigation(this._defaultNavigation);

        // -----------------------------------------------------------------------------------------------------
        // @ Search results - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('api/common/search')
            .reply(({request}) => {

                // Get the search query
                const query = cloneDeep(request.body.query.toLowerCase());

                // If the search query is an empty string,
                // return an empty array
                if (query === '') {
                    return [200, {results: []}];
                }


                // Filter the navigation
                const pagesResults = cloneDeep(flatNavigation)
                    .filter(page => (page.title?.toLowerCase().includes(query) || (page.subtitle && page.subtitle.includes(query))));


                // Filter the main
                const modelsResults = cloneDeep(this._models)
                    .filter(model => model.title.toLowerCase().includes(query));

                // Prepare the results array
                const results = [];


                // If there are page results...
                if (pagesResults.length > 0) {
                    // Normalize the results
                    pagesResults.forEach((result: any) => {

                    });

                    // Add to the results
                    results.push({
                        id: 'pages',
                        label: 'Pages',
                        results: pagesResults
                    });
                }

                // If there are main results...
                if (modelsResults.length > 0) {
                    // Normalize the results
                    modelsResults.forEach((result) => {

                        // Add a link
                        result.link = '/main';
                    });

                    // Add to the results
                    results.push({
                        id: 'main',
                        label: 'Models',
                        results: modelsResults
                    });
                }


                // Return the response
                return [200, results];
            });


        this._fuseMockApiService
            .onPost('api/common/search/main')
            .reply(({request}) => {

                // Get the search query
                const query = cloneDeep(request.body.query.toLowerCase());

                // If the search query is an empty string,
                // return an empty array
                if (query === '') {
                    return [200, {results: []}];
                }

                // Filter the main
                const modelsResults = cloneDeep(this._models)
                    .filter(model => model.title.toLowerCase().includes(query));

                // Prepare the results array
                const results = [];

                // If there are main results...
                if (modelsResults.length > 0) {
                    // Normalize the results
                    modelsResults.forEach((result) => {

                        // Add a link
                        result.link = '/main';
                    });

                    // Add to the results
                    results.push({
                        id: 'main',
                        label: 'Models',
                        results: modelsResults
                    });
                }

                // Return the response
                return [200, results];
            });
    }
}
