export const environment = {
    bugsnagAPIKey: '9d81874e3bec421999f0d7eddcfc2ffe',
    apiBaseUrl: 'https://api.vuframe.com',
    egloMiddlewareAPI: 'https://eglo-middleware-js-dev.herokuapp.com',
    spaceToken: 'bf90130eee492e1d81ae2f2ad1b199da7b007a5063f88436',
    studioLink: 'https://www.vuframe.studio',
    ssoAppToken: '1f6cb64176f7824c1419910871ee79bbc60031d1ae80dd17c5',
    accessSecret: '071c2655ed51439a9f3938624a0686da14b6bb27f3f04688d5',
    auraAPISecret: 'cce2e72e065b1edb23def1b3e15183d07e1e55e9efca4e8294',
    apiUsername: 'c8742d24e8e42e9e890631aa2f85b549645fa87bd19d6c986a',
    apiToken: '94ed272f6a5752b2cb3fc4c9882e8b6cd5521b008d781db44e',
    version: '1.7.8',
    production: true,
    appToken: '2fcf8e93d3483814dedae8e506a9ab7af299bf4b3cd74411',
    TD_USERNAME:'undefined',
    TD_PASSWORD: 'undefined',
    TD_TOKEN: 'undefined',
    TD_BASE_URL: 'undefined'
};
