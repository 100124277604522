/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'main',
        title: 'SmartVu\'s®',
        type : 'basic',
        icon : 'heroicons_outline:cube',
        link : '/main'
    },
    // {
    //     id   : 'materials',
    //     title: 'Material Library',
    //     type : 'basic',
    //     icon : 'heroicons_outline:color-swatch',
    //     link : '/material-library'
    // },
    // {
    //     id   : 'pipelines',
    //     title: 'Pipelines',
    //     type : 'basic',
    //     icon : 'heroicons_outline:database',
    //     link : '/pipelines'
    // },
    // {
    //     id: 'divider2',
    //     type: 'divider'
    // },
    // {
    //     id   : 'learn',
    //     title: 'Learn',
    //     type : 'basic',
    //     icon : 'heroicons_outline:book-open',
    //     link : '/learn'
    // },
    // {
    //     id: 'divider2',
    //     type: 'divider'
    // },
    // {
    //     id: 'divider2',
    //     type: 'group',
    //     title:'Group',
    //     subtitle:'SubGroup',
    //     icon: 'heroicons_outline:chart-pie',
    // },
    {
        id   : 'metadata',
        title: 'Metadata',
        type : 'basic',
        icon : 'heroicons_outline:database',
        link : '/metadata'
    }
];
