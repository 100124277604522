<div class="flex gap-4">
    <mat-form-field appearance="fill" class="flex-auto gt-xs:pr-3">
        <mat-select placeholder="Select issue" [(ngModel)]="selectedItem" [formControl]="issue" (selectionChange)="onSelectionChange($event)"
                    [multiple]="isMultiselect">
            <search-bar *ngIf="hasSearchBar" (sendSearchValue)="onSearchValue($event)" [useSearchValueSubscription]="false"></search-bar>
            <mat-option *ngFor="let item of issueList | MenuSearchPipe: searchValue"
                        #titleElement
                        [matTooltip]="item.title"
                        [matTooltipDisabled]="disableTooltip(titleElement)"
                        [value]="item">{{item.title}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
