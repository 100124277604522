<div class="flex justify-center items-center flex-col w-full">
    <div class="border-dashed border-2 rounded-lg flex justify-center items-center flex-col gap-3 p-5">
        <button mat-fab color="warn" class="mb-3">
            <mat-icon>warning</mat-icon>
        </button>

        <h3 class="font-bold text-4xl">Access is Blocked</h3>

        <p>Your access has been blocked.</p>

        <button mat-raised-button (click)="logout()" class="mt-5">Try again</button>
    </div>
</div>
