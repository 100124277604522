import {Action, createReducer, on} from '@ngrx/store';
import * as SmartvusActions from './smartvus.actions';
import {initialState} from './smartvus.state';

const smartvusReducer = createReducer(
    initialState,
    on(SmartvusActions.fetchSmartVusSuccess, (state, {smartvus}) => {
        const newSmartVusArray = [...state.smartvus, ...smartvus];

        return {
            ...state,
            currentChunk: smartvus,
            smartvus: newSmartVusArray
        }

    }),
    on(SmartvusActions.updateSmartVuStatusSuccess, SmartvusActions.publishSmartVuSuccess, (state, {token, groups}) => {
        const smartvu = state.smartvus.find(item => item.smartVuToken === token);

        /** After the change of the SmartVu Group we are modifying the array with all SmartVu's, so we can handle the group change dynamically in the UI.
         *
         * token - the SmartVu Token
         * groups - the new groups property returned from the API response
         */

        const index = state.smartvus.indexOf(smartvu);
        const updatedSmartVus = [...state.smartvus];

        if (index !== -1 && index !== undefined && index !== null) {
            updatedSmartVus[index].groups = groups;

            return {
                ...state,
                smartvus: updatedSmartVus,
            };
        }

        return {...state, smartvus: state.smartvus}


    }),
);

export function reducer(state: any | undefined, action: Action) {
    return smartvusReducer(state, action);
}
