import {Component, Input, OnInit} from '@angular/core';
import {BaseMenuComponent} from '../base-menu.component';
import {FormControl} from '@angular/forms';
import {IssueType} from '../../../interfaces/interfaces';
import {MatOption} from "@angular/material/core";

@Component({
    selector: 'simple-menu',
    templateUrl: './simple-menu.component.html',
    styleUrls: ['./simple-menu.component.scss'],

})
export class SimpleMenuComponent extends BaseMenuComponent implements OnInit {

    @Input() set availableIssues(issues: Array<IssueType>) {
        if (issues) {
            this.issueList = issues;
        }
    }

    issue = new FormControl();
    issueList: Array<IssueType>;

    constructor() {
        super();
    }

    ngOnInit(): void {
    }

    disableTooltip(element: MatOption) {
        return element._getHostElement().firstElementChild.scrollWidth === element._getHostElement().firstElementChild.clientWidth;
    }

}
