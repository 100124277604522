<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="relative flex flex-0 justify-between items-center w-full h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">

        <div class="flex gap-1 cursor-pointer" [routerLink]="['/products']" queryParamsHandling="preserve">
            <img
                class="w-32"
                alt="EGLO logo"
                src="../../assets/images/logo/egloleuchten_160450242345c45971de.jpg">

            <mat-divider [vertical]="true"></mat-divider>

            <div class="flex flex-col items-center justify-center ml-1">
                <span class="font-extrabold">EGLO Cockpit</span>
                <span class="text-xs">v{{ appVersion }} by Vuframe®</span>
            </div>
        </div>


        <search-bar id="search-bar" class="w-1/4" *ngIf="(mainService.hideSearchBar$ | async) === false" [useSearchValueSubscription]="true"></search-bar>

        <div class="flex items-center space-x-0.5 sm:space-x-2" *ngIf="(mainService.hideSearchBar$ | async) && scrolled">
            <img [src]="scrolled.image" class="rounded-xl w-15" alt="SmartVu Image"/>
            <span class="font-extrabold">{{scrolled.title}}</span>
        </div>


        <!-- Components -->
        <div class="flex items-center gap-2 pl-2 space-x-0.5 sm:space-x-2">
            <mat-slide-toggle #toggle *ngIf="mainService.isAdmin$ | async" [checked]="checked"
                              (change)="mainService.appMode(toggle.checked)">{{toggle.checked ? 'Admin Mode' : 'User Mode'}}</mat-slide-toggle>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>

    </div>

</div>

