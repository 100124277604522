import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import {AuthService} from '@auth0/auth0-angular';
import {Router} from '@angular/router';

@Component({
    selector: 'app-authorization',
    templateUrl: './authorization.component.html',
    styleUrls: ['./authorization.component.scss']
})
export class AuthorizationComponent implements OnInit {

    constructor(private _apiService: ApiService,
                private _auth0: AuthService,
                private _router: Router) {
    }

    ngOnInit(): void {
    }

    logout(): void {
        this._auth0.logout({returnTo: window.location.origin});
    }

}
