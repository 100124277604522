import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MetadataObject} from '../metadata-upload.types';
import {Group} from '../../../shared/interfaces/interfaces';
import {Status} from '../../../shared/enums/enums';

@Injectable({providedIn: 'root'})
export class MetadataUploadService {

    private _isJobProcessing = new BehaviorSubject(null);
    isJobProcessing$ = this._isJobProcessing.asObservable();

    constructor() {
    }

    parseProductPhotos(value): any[] {
        return value.split(/\r?\n/);
    }

    getProductPhotos(...args): string[] {
        const productPhotosArray = [];
        const parameters = args.filter(el => el !== undefined);
        return productPhotosArray.concat.apply([], parameters).splice(0, 4);
    }

    setIsJobProcessing(value: boolean): void {
        this._isJobProcessing.next(value);
    }

    /**
     * This method checks if the SmartVu has a Status. If not it prepares a group which will be added with an API call in the middleware after metadata import.
     *
     * @param smartVuGroups - the groups of the SmartVu
     * @param allSpaceGroups - all groups available for the Space
     */
    groupsToSet(smartVuGroups: Group[], allSpaceGroups: Group[]): Group[] | boolean {
        if (!this.isInStatus([Status.rejected, Status.bugfixing, Status.invalidSource, Status.approved, Status.published, Status.toReview], smartVuGroups)) {
            return [this.getGroupByStatus(Status.toReview, allSpaceGroups)];
        } else {
            return false;
        }
    }

    isInStatus(status: string[], smartVuGroups: Group[]): boolean {
        return smartVuGroups.some((el: Group) => status.includes(el.title));
    }

    getGroupByStatus(status: Status, allSpaceGroups: Group[]): Group {
        for (const group of allSpaceGroups) {
            if (group.status === status) {
                return group;
            }
        }
        return null;
    }

}
