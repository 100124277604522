import {NgModule, ErrorHandler} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {MarkdownModule} from 'ngx-markdown';
import {FuseModule} from '@fuse';
import {FuseConfigModule} from '@fuse/services/config';
import {FuseMockApiModule} from '@fuse/lib/mock-api';
import {CoreModule} from 'app/core/core.module';
import {appConfig} from 'app/core/config/app.config';
import {mockApiServices} from 'app/mock-api';
import {LayoutModule} from 'app/layout/layout.module';
import {AppComponent} from 'app/app.component';
import {appRoutes} from 'app/app.routing';
import {AuthHttpInterceptor, AuthModule} from '@auth0/auth0-angular';
import auth0_config from '../../auth0.config.json';
import Bugsnag from '@bugsnag/js';
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular';
import {environment} from '../environments/environment';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthorizationModule} from './modules/authorization/authorization.module';
import {XstateAngular} from "xstate-angular";
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {SmartvusEffects} from "./store/smartvus";
import {metaReducers, reducers} from "./store";
import {ErrorComponent} from './modules/error/error.component';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";

Bugsnag.start({apiKey: environment.bugsnagAPIKey, enabledReleaseStages: ['production']});

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
    return new BugsnagErrorHandler();
}


@NgModule({
    declarations: [
        AppComponent,
        ErrorComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),

        //Auth0 Module
        AuthModule.forRoot({
            domain: auth0_config.domain,
            clientId: auth0_config.clientId,
            audience: 'https://api.vuframe.com',
            issuer: 'https://id.vuframe.com/',
            responseType: 'token id_token',
            scope: 'openid email profile',
            useRefreshTokens: true,
            cacheLocation: 'localstorage',
            skipRedirectCallback: false,
            httpInterceptor: {
                allowedList:
                    [
                        `${environment.apiBaseUrl}/v4/spaces/${environment.spaceToken}/smartvus`,
                        `${environment.apiBaseUrl}/v4/smartvus/*`,
                        `${environment.apiBaseUrl}/v4/issues/*`,
                        `${environment.apiBaseUrl}/v4/issues`
                    ]
            },
            errorPath: '/error',
        }),

        AuthorizationModule,

        StoreModule.forRoot(reducers, {metaReducers}),

        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),

        EffectsModule.forRoot([SmartvusEffects]),
        MatButtonModule,
        MatIconModule
    ],
    providers:
        [
            {provide: ErrorHandler, useFactory: errorHandlerFactory},
            {
                provide: HTTP_INTERCEPTORS,
                useClass: AuthHttpInterceptor,
                multi: true,
            },
            XstateAngular
        ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
