import {createAction, props} from '@ngrx/store';
import {Group} from "../../shared/interfaces/interfaces";

export const appLoaded = createAction('[App] App Loaded');

export const fetchSmartVusSuccess = createAction(
    '[Vuframe API] Fetch SmartVus Success',
    props<{ smartvus: any[] }>()
);

export const fetchSmartVusFailed = createAction(
    '[Vuframe API] Fetch SmartVus Failed',
    props<{ error: any }>()
);

export const updateSmartVuStatus = createAction(
    '[Product Details Page] Update SmartVu Status',
    props<{ token: string, group: Group, customJson: any }>()
);

export const updateSmartVuStatusSuccess = createAction(
    '[Product Details Page] Update SmartVu Status Success',
    props<{ token: string, groups: any }>()
);

export const publishSmartVu = createAction(
    '[Product Details Page] Publish SmartVu',
    props<{ token: string, typeCode: string, group: Group, customJson: any }>()
);

export const publishSmartVuSuccess = createAction(
    '[Product Details Page] Publish SmartVu Success',
    props<{ token: string, groups: any }>()
);
