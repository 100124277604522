<!-- Basic search -->
<ng-container>
    <div class="w-full">
        <mat-form-field class="fuse-mat-no-subscript w-full">
            <mat-icon
                matPrefix
                [svgIcon]="'heroicons_outline:search'"></mat-icon>
            <input
                matInput
                [ngModel]="searchValue"
                (ngModelChange)="onChange($event)">
            <mat-icon
                    class="cursor-pointer"
                    *ngIf="searchValue"
                    (click)="onChange('')"
                    matTooltip="Clear"
                    matSuffix
                    [svgIcon]="'mat_solid:close'"></mat-icon>
        </mat-form-field>
    </div>
</ng-container>
