import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
/**
 * This service handles the logic for the Product Details Component.
 */
export class ProductDetailsService {
    private _scrolled: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    scrolled$: Observable<any> = this._scrolled.asObservable();

    constructor() {
    }

    scrolled(value: any) {
        this._scrolled.next(value);
    }
}
