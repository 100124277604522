<div class="flex justify-end">
    <mat-icon svgIcon="mat_outline:close" class="cursor-pointer" mat-dialog-close></mat-icon>
</div>

<div class="flex flex-col gap-4 px-8 py-6 pb-4 ml-2 w-full max-w-3xl" *ngIf="!isLoading && availableIssues.length > 0">

    <div>
        <div>
            <h3 class="text-xl font-semibold">Add Issue</h3>

        </div>
    </div>

    <simple-menu class="w-full"
                 [hasSearchBar]="true"
                 [hasImagePreview]="false"
                 [isMultiselect]="false"
                 [availableIssues]="availableIssues"
                 #issuesMenu>
    </simple-menu>

    <mat-form-field class="example-full-width">
        <mat-label>Leave a comment</mat-label>
        <textarea matInput [(ngModel)]="textAreaValue"></textarea>
    </mat-form-field>

    <button
            class="w-1/3 self-center"
            (click)="createIssue(issuesMenu.selectedItem)"
            mat-dialog-close="true"
            mat-raised-button
            color="success">
        <mat-icon class="icon-size-4 mr-1"
                  svgIcon="heroicons_outline:plus"></mat-icon>
        <span class="mr-1">Add</span>
    </button>


</div>

<div class="mt-5" *ngIf="isLoading">
    <div>
        <mat-spinner class="m-auto"></mat-spinner>
        <p class="text-center mt-3">Generating available issues...</p>
    </div>

</div>

<div *ngIf="!(availableIssues.length > 0) && !isLoading" class="text-center">
    <mat-icon svgIcon="heroicons_outline:exclamation-circle"></mat-icon>
    <p class="text-center">There are no issues left to be added for this SmartVu&reg;</p>
</div>
