import {Injectable} from '@angular/core';
import {Status} from '../enums/enums';
import {DeviceDetectorService} from "ngx-device-detector";

@Injectable({providedIn: 'root'})
/**
 * This service will hold methods which are used
 * by different components or services throughout the app
 */
export class SharedService {
    constructor(private deviceService: DeviceDetectorService) {
    }

    getRedirectLink(shareCode) {
        if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
            if (this.deviceService.getDeviceInfo().os.includes('Android')) {
                window.location.href = `intent://share/${shareCode}#Intent;scheme=vuframe;package=com.vuframe.viewer;end`;
                return;
            }
            window.location.href = 'https://vuframe.app.link?id=' + shareCode;
            return;
        }

        const anchor = document.createElement('a');
        anchor.href = 'https://share.vuframe.com/' + shareCode;
        anchor.target="_blank";
        anchor.click();
        return;
    }

    getStatusByTitle(title: string): Status {
        const index = Object.values(Status).indexOf(title as unknown as Status);
        const key = Object.keys(Status)[index];

        return Status[key];
    }

    parseTextUsedForFiltering(text: string): string {
        return text.replace(' ', '_').toLowerCase().substring(text.indexOf('-') + 1);
    }
}
