import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ApiService} from '../../shared/services/api.service';
import {catchError, finalize, map, of, switchMap, take, tap} from 'rxjs';
import * as SmartvuActions from './smartvus.actions';
import {Group} from "../../shared/interfaces/interfaces";
import {MainService} from "../../modules/main/services/main.service";
import {SummaryService} from "../../modules/main/components/summary/summary.service";
import {Store} from "@ngrx/store";
import {selectSmartVuItems} from "./smartvus.selector";
import {FilterService} from "../../modules/main/services/filter.service";

@Injectable()
export class SmartvusEffects {
    private _smartvus$ = this.store.select(selectSmartVuItems);

    constructor(private actions$: Actions<any>,
                private apiService: ApiService,
                private mainService: MainService,
                private summaryService: SummaryService,
                private store: Store,
                private filterService: FilterService) {
    }

    fetchSmartvus = createEffect(() =>
        this.actions$.pipe(
            // you can pass in multiple actions here that will trigger the same effect
            ofType(SmartvuActions.appLoaded.type),
            switchMap(() =>
                this.apiService.allSmartVus().pipe(
                    map(({smartvus}, index) => {

                            if (index === 0) {
                                this.filterService.prepareSidebar();
                                this.filterService.checkForActiveFilters();
                            }

                            const generatedItems = this.mainService.generateCockpitItems(smartvus, null)
                            this.summaryService.setSummaryCounters(generatedItems);
                            return SmartvuActions.fetchSmartVusSuccess({smartvus: generatedItems})
                        }
                    ),
                    finalize(() => {
                        this.apiService.allIssues$.pipe(
                            switchMap(
                                (issues: any) => this._smartvus$.pipe(
                                    map((smartvus) => {
                                        return {issues: issues, smartvus: smartvus}
                                    })
                                )
                            )
                        ).subscribe(
                            (response) => {
                                for (const smartvu of response.smartvus) {
                                    smartvu.setIssues(response.issues)
                                }

                                this.filterService.prepareIssues(response.issues);
                                this.filterService.checkForActiveFilters();
                                this.filterService.parseBadgeTitleNumbers(response.smartvus, false);
                            }
                        )
                    }),
                    catchError((error) =>
                        of(SmartvuActions.fetchSmartVusFailed({error: error}))
                    )
                )
            )
        )
    );

    updateSmartVuStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SmartvuActions.updateSmartVuStatus),
            switchMap((action) =>
                this.apiService.getSmartVuGroups(action.token)
                    .pipe(
                        tap(() => this.apiService.updateGroupChangeTimestamp(action.token, action.customJson)
                            .pipe(take(1))),
                        switchMap((groups) => {
                            groups = this.replaceStatusGroupInGroups(action.group, groups);
                            return this.apiService.replaceSmartVuGroups(action.token, groups);
                        }),
                        map(response => SmartvuActions.updateSmartVuStatusSuccess({token: action.token, groups: response}))
                    ),
            )
        ))

    publishSmartVu$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SmartvuActions.publishSmartVu),
            switchMap((action) =>
                this.apiService.publishSmartVu(action.token, action.customJson)
                    .pipe(
                        switchMap(() => this.apiService.getSmartVuGroups(action.token)),
                        switchMap((groups) => {
                            groups = this.replaceStatusGroupInGroups(action.group, groups);
                            return this.apiService.replaceSmartVuGroups(action.token, groups);
                        }),
                        map(response => SmartvuActions.publishSmartVuSuccess({token: action.token, groups: response})),
                    ),
            )
        ))

    replaceStatusGroupInGroups(statusGroup: Group, groups: Group[]): Group[] {
        const filteredGroups = groups.filter(group => group.status === undefined || group.status === null);
        filteredGroups.push(statusGroup);
        return filteredGroups;
    }
}
