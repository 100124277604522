import {Group} from "../interfaces/interfaces";
import {Status} from "../enums/enums";

export function parseTextUsedForFiltering(text: string): string {
    return text.replace(' ', '_').toLowerCase().substring(text.indexOf('-') + 1);
}

export function convertGroups(rawGroups: any[]): Array<Group> {
    if (rawGroups === null || rawGroups.length === 0) {
        return [];
    }
    const groups = [];

    for (const rawGroup of rawGroups) {
        const status = getStatusByTitle(rawGroup.title);
        if (status) {
            groups.push({
                title: rawGroup.title,
                token: rawGroup.token,
                status: status
            });
        }

    }

    if (groups.length > 1) {
        for (const group of groups) {
            switch (group.status) {
                case Status.invalidSource:
                    return [group];
                case Status.bugfixing:
                    return [group];
            }
        }
    }

    return groups;
}

export function getStatusByTitle(title: string): Status {
    const index = Object.values(Status).indexOf(title as unknown as Status);
    const key = Object.keys(Status)[index];

    return Status[key];
}

export function getSmartVuUploadType(customJson: any): string {
    if (customJson.source_file_updated_at) {
        return 'ftp';
    } else {
        return 'manual';
    }
}

export function getPreviewImage(initialFeatureObject) {
    let imageUrl = 'assets/images/no-render-image.png';
    const productPhotos = initialFeatureObject.custom_json?.metadata?.product_photos;
    if (productPhotos != null && productPhotos.length !== 0) {
        imageUrl = productPhotos[0];
    }

    return imageUrl;
}
