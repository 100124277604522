import {Component, OnInit} from '@angular/core';
import {appLoaded} from "./store/smartvus";
import {Store} from "@ngrx/store";
import {AuthService} from "@auth0/auth0-angular";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    /**
     * Constructor
     */
    constructor(private _store: Store,
                private _authService: AuthService
    ) {
    }

    ngOnInit(): void {

        this._authService.isAuthenticated$.subscribe(res => {
            if(res) {
                this._store.dispatch(appLoaded());
            }
        });
    }
}
