/**
 * Enum holding the available SmartVu Groups.
 */
export enum Status {
    approved = '-Approved',
    rejected = '-Rejected',
    toReview = '-To Review',
    bugfixing = '-Bugfixing',
    invalidSource = '-Invalid Source',
    published = '-Published',
    deleted = '-Deleted',
    new = '-New'
}

export enum FilterCategories {
    status = 'status',
    placement = 'placement',
    category = 'category',
    opened_issues = 'opened_issues',
    closed_issues = 'closed_issues',
    processing_status = 'processing_status'
}

export enum CockpitItemLinks {
    embed = 'https://embed.vuframe.com',
    share = 'https://share.vuframe.com',
    studio = 'studio',
    model = 'model'
}

export enum UploadType {
    ftp = 'ftp',
    manual = 'manual'
}

export enum ProcessingStatus {
    ready = 'ready',
    pending = 'pending',
    failed = 'failed',
}
